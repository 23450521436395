import React from 'react';

export const OpApprovedSmall = () => (
  <svg
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2455_67574)">
      <path
        d="M61.54 2C73.3159 2 84.8274 5.49196 94.6187 12.0343C104.41 18.5766 112.041 27.8755 116.548 38.755C121.054 49.6345 122.233 61.606 119.936 73.1556C117.639 84.7053 111.968 95.3143 103.641 103.641C95.3143 111.968 84.7053 117.639 73.1557 119.936C61.6061 122.233 49.6346 121.054 38.7551 116.548C27.8755 112.041 18.5767 104.41 12.0343 94.6187C5.49199 84.8273 2.00003 73.3159 2.00003 61.54C2.01856 45.7547 8.29745 30.6212 19.4594 19.4593C30.6213 8.29739 45.7547 2.01853 61.54 2ZM61.54 0C49.3686 0 37.4704 3.60924 27.3502 10.3713C17.23 17.1334 9.3423 26.7447 4.68448 37.9896C0.0266643 49.2346 -1.19203 61.6082 1.1825 73.5458C3.55704 85.4834 9.41815 96.4488 18.0247 105.055C26.6312 113.662 37.5966 119.523 49.5342 121.898C61.4718 124.272 73.8454 123.053 85.0904 118.396C96.3353 113.738 105.947 105.85 112.709 95.7298C119.471 85.6096 123.08 73.7114 123.08 61.54C123.08 45.2186 116.596 29.5656 105.055 18.0246C93.5144 6.48361 77.8615 0 61.54 0Z"
        fill="#C83D56"
      />
      <path
        d="M50.72 86.4L24.72 60.4C24.284 59.9616 24.0392 59.3684 24.0392 58.7501C24.0392 58.1317 24.284 57.5385 24.72 57.1L26.87 54.98C27.3084 54.544 27.9017 54.2993 28.52 54.2993C29.1383 54.2993 29.7315 54.544 30.17 54.98L50.69 75.5001C51.1301 75.9367 51.725 76.1816 52.345 76.1816C52.965 76.1816 53.5598 75.9367 54 75.5001L92.87 36.6801C93.0858 36.4618 93.3427 36.2884 93.6259 36.1702C93.9092 36.0519 94.2131 35.991 94.52 35.991C94.8269 35.991 95.1308 36.0519 95.414 36.1702C95.6973 36.2884 95.9542 36.4618 96.17 36.6801L98.32 38.82C98.7566 39.2602 99.0016 39.8551 99.0016 40.475C99.0016 41.095 98.7566 41.6899 98.32 42.1301L54.03 86.4C53.5898 86.8367 52.995 87.0817 52.375 87.0817C51.755 87.0817 51.1601 86.8367 50.72 86.4Z"
        fill="#C83D56"
      />
    </g>
    <defs>
      <clipPath id="clip0_2455_67574">
        <rect width="123.07" height="123.07" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import React from 'react';

export const ProfilePicturePlaceHolder = ({ color = '#24252C' }: { color: string }) => (
  <svg
    width="164"
    height="165"
    viewBox="0 0 164 165"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.05005"
      y="4.0542"
      width="157.9"
      height="157.9"
      rx="78.95"
      stroke="black"
      strokeWidth="2"
      strokeDasharray="4 4"
    />
    <circle cx="80.5847" cy="64.5911" r="17.1687" stroke={color} strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.715 116.512C111.754 115.871 111.775 115.225 111.775 114.574C111.775 97.4831 97.9196 83.6282 80.8286 83.6282C63.7376 83.6282 49.8827 97.4831 49.8827 114.574C49.8827 115.225 49.9028 115.871 49.9424 116.512H47.9387C47.9015 115.871 47.8827 115.225 47.8827 114.574C47.8827 96.3786 62.6331 81.6282 80.8286 81.6282C99.0241 81.6282 113.775 96.3786 113.775 114.574C113.775 115.225 113.756 115.871 113.718 116.512H111.715Z"
      fill={color}
    />
  </svg>
);

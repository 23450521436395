import { css } from '@emotion/react';
import React from 'react';
import colors from '../theme/colors';
import Icon from './icon/Icon';
import { Text } from './Text';

interface ToastContextInterface {
  add: (newToast: ToastInterface) => void;
  remove: (id: number) => void;
}
export interface ToastInterface {
  heading: string;
  text: string;
  variant: 'warning' | 'error' | 'info';
}
const Ctx = React.createContext<ToastContextInterface>({} as ToastContextInterface);

const ToastContainer = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    css={css`
      z-index: 101;
      position: fixed;
      left: 0%;
      bottom: 0;
    `}
    {...props}
  />
);
const variantStyle = {
  warning: css`
    background-color: orange;
  `,
  error: css`
    background-color: ${colors.error};
  `,
  info: css`
    background-color: ${colors.darkGray};
  `,
};
const Toast = ({
  heading,
  text,
  variant,
  onDismiss,
}: {
  onDismiss: () => void;
} & ToastInterface) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 10px;
      margin: 10px;
      ${variantStyle[variant]}
    `}
  >
    <a
      onClick={onDismiss}
      css={css`
        cursor: pointer;
      `}
    >
      <Icon icon="Close" />
    </a>
    <Text variant="Caption">{heading}</Text>
    <Text variant="Body">{text}</Text>
  </div>
);

let toastCount = 0;

export function ToastProvider({ children }: React.HTMLAttributes<HTMLDListElement>) {
  const [toasts, setToasts] = React.useState<(ToastInterface & { id: number })[]>([]);

  const add = (newToast: ToastInterface) => {
    const id = toastCount++;
    const toast = { ...newToast, id };
    setToasts([...toasts, toast]);
  };
  const remove = (id: number) => {
    const newToasts = toasts.filter((t) => t.id !== id);
    setToasts(newToasts);
  };

  return (
    <Ctx.Provider value={{ add, remove }}>
      {children}
      <ToastContainer>
        {toasts.map(({ heading, text, variant, id, ...rest }) => (
          <Toast
            key={id}
            heading={heading}
            text={text}
            variant={variant}
            onDismiss={() => remove(id)}
            {...rest}
          />
        ))}
      </ToastContainer>
    </Ctx.Provider>
  );
}

export const useToasts = () => React.useContext(Ctx);

import React from 'react';

export const User = ({ color = '#F8F8FD' }: { color: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 56 57" fill="none">
    <circle cx="27.5" cy="22.3345" r="6.5" stroke={color} strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.1728 42.8341C39.1909 42.5697 39.2 42.303 39.2 42.0341C39.2 35.6276 34.0065 30.4341 27.6 30.4341C21.1935 30.4341 16 35.6276 16 42.0341C16 42.303 16.0091 42.5697 16.0272 42.8341H14.0231C14.0078 42.5694 14 42.3026 14 42.0341C14 34.523 20.0889 28.4341 27.6 28.4341C35.1111 28.4341 41.2 34.523 41.2 42.0341C41.2 42.3026 41.1922 42.5694 41.1769 42.8341H39.1728Z"
      fill={color}
    />
  </svg>
);

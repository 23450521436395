import * as React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { TABLET } from '../theme/breakpoints';

export type TextVariant =
  | 'H1'
  | 'H2'
  | 'H3'
  | 'H4'
  | 'Ingress'
  | 'IngressMedium'
  | 'IngressOblique'
  | 'Body'
  | 'BodyMedium'
  | 'BodyLightOblique'
  | 'Caption'
  | 'CaptionSmall'
  | 'Hand'
  | 'CaptionSmall'
  | 'Details'
  | 'H1Graphic'
  | 'H2Graphic';

export const textVariantStyles: Record<TextVariant, SerializedStyles> = {
  H1: css`
    font-family: GT-Sectra;
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 70px;
    @media (min-width: ${TABLET}px) {
      font-size: 100px;
      line-height: 117px;
    }
  `,
  H1Graphic: css`
    font-family: GT-Sectra;
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 33px;
    @media (min-width: ${TABLET}px) {
      font-size: 100px;
      line-height: 70px;
    }
  `,
  H2: css`
    font-family: GT-Sectra;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    font-size: 36px;
    @media (min-width: ${TABLET}px) {
      font-size: 40px;
      line-height: 48px;
    }
  `,
  H2Graphic: css`
    font-family: GT-Sectra;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
    font-size: 25px;
    @media (min-width: ${TABLET}px) {
      font-size: 60px;
      line-height: 42px;
    }
  `,
  H3: css`
    font-family: GT-Walsheim;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    font-size: 28px;
    @media (min-width: ${TABLET}px) {
      font-size: 28px;
      line-height: 36px;
    }
  `,
  H4: css`
    font-family: GT-Sectra;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-size: 20px;
    @media (min-width: ${TABLET}px) {
      line-height: 24px;
      font-size: 20px;
    }
  `,
  Ingress: css`
    font-family: GT-Walsheim;
    font-style: normal;
    font-weight: 200;
    line-height: 40px;
    font-size: 28px;
    @media (min-width: ${TABLET}px) {
      line-height: 40px;
      font-size: 28px;
    }
  `,
  IngressMedium: css`
    font-family: GT-Walsheim;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    font-size: 28px;
    @media (min-width: ${TABLET}px) {
      line-height: 40px;
      font-size: 28px;
    }
  `,
  IngressOblique: css`
    font-family: GT-Walsheim;
    font-weight: 200;
    font-style: oblique;
    line-height: 40px;
    font-size: 28px;
    @media (min-width: ${TABLET}px) {
      line-height: 40px;
      font-size: 28px;
    }
  `,
  Body: css`
    font-family: GT-Walsheim;
    font-style: normal;
    line-height: 36px;
    font-size: 20px;
    font-weight: 300;
    @media (min-width: ${TABLET}px) {
      line-height: 36px;
      font-size: 20px;
    }
  `,
  BodyMedium: css`
    font-family: GT-Walsheim;
    font-style: normal;
    line-height: 36px;
    font-size: 20px;
    font-weight: 500;
    @media (min-width: ${TABLET}px) {
      line-height: 36px;
      font-size: 20px;
    }
  `,
  BodyLightOblique: css`
    font-family: GT-Walsheim;
    font-style: oblique;
    font-weight: 300;
    line-height: 36px;
    font-size: 20px;
    @media (min-width: ${TABLET}px) {
      line-height: 36px;
      font-size: 20px;
    }
  `,
  Caption: css`
    font-family: 'GT-Walsheim';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1px;
    @media (min-width: ${TABLET}px) {
      font-size: 16px;
      line-height: 24px;
    }
  `,
  CaptionSmall: css`
    font-family: GT-Walsheim;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 8px;
    @media (min-width: ${TABLET}px) {
      font-size: 10px;
      line-height: 8px;
    }
  `,
  Hand: css`
    font-family: Northwell;
    font-style: normal;
    font-weight: 400;
    font-size: 68px;
    line-height: 120px;
    @media (min-width: ${TABLET}px) {
      font-size: 68px;
      line-height: 120px;
    }
  `,
  Details: css`
    font-family: GT-Walsheim;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    @media (min-width: ${TABLET}px) {
      font-size: 16px;
      line-height: 24px;
    }
  `,
};

const variantDefaultTags: Record<TextVariant, string> = {
  H1: 'h1',
  H1Graphic: 'h1',
  H2: 'h2',
  H2Graphic: 'h2',
  H3: 'h3',
  H4: 'h4',
  Ingress: 'div',
  IngressMedium: 'div',
  IngressOblique: 'div',
  Body: 'div',
  BodyMedium: 'div',
  BodyLightOblique: 'div',
  Caption: 'div',
  CaptionSmall: 'div',
  Hand: 'div',
  Details: 'div',
};

export const Text = ({
  children,
  tag,
  variant = 'Body',
  pre,
  color,
  ...rest
}: {
  children?: React.ReactNode;
  tag?: string;
  variant: TextVariant;
  /** Applies `pre` styling, preserving whitespace. */
  pre?: boolean;
  color?: string;
} & React.HTMLAttributes<HTMLElement>) => {
  const Tag = (tag || variantDefaultTags[variant]) as any;
  return (
    <Tag
      css={css`
        line-height: 1.4;
        ${textVariantStyles[variant]};
        ${pre &&
        css`
          white-space: pre-wrap;
        `};
        color: ${color ? color : 'unset'};
      `}
      {...rest}
    >
      {children}
    </Tag>
  );
};

import React from 'react';

export const SustainableSmall = () => (
  <svg width="32" height="32" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2455_67603)">
      <path
        d="M30.94 92.3101L28.78 91.3101C28.5912 91.2224 28.4226 91.0966 28.2847 90.9407C28.1468 90.7847 28.0427 90.6019 27.9788 90.4037C27.9149 90.2056 27.8927 89.9964 27.9136 89.7892C27.9345 89.5821 27.9979 89.3815 28.1 89.2001C29.8564 86.0971 31.9526 83.1993 34.35 80.5601C34.4879 80.4079 34.6551 80.285 34.8415 80.1988C35.028 80.1125 35.2299 80.0647 35.4352 80.0581C35.6405 80.0516 35.845 80.0865 36.0365 80.1607C36.2281 80.2349 36.4027 80.347 36.55 80.4901L38.26 82.1601C38.5421 82.4325 38.7071 82.804 38.7202 83.1959C38.7332 83.5878 38.5933 83.9694 38.33 84.2601C36.262 86.5472 34.4481 89.0519 32.92 91.7301C32.7218 92.0563 32.4088 92.2967 32.0425 92.404C31.6762 92.5113 31.2829 92.4779 30.94 92.3101V92.3101Z"
        fill="#179039"
      />
      <path
        d="M61.54 2C73.3159 2 84.8274 5.49196 94.6187 12.0343C104.41 18.5766 112.041 27.8755 116.548 38.755C121.054 49.6345 122.233 61.606 119.936 73.1556C117.639 84.7053 111.968 95.3143 103.641 103.641C95.3144 111.968 84.7053 117.639 73.1557 119.936C61.6061 122.233 49.6346 121.054 38.7551 116.548C27.8755 112.041 18.5767 104.41 12.0343 94.6187C5.492 84.8273 2.00004 73.3159 2.00004 61.54C2.01856 45.7547 8.29745 30.6212 19.4594 19.4593C30.6213 8.29739 45.7547 2.01853 61.54 2M61.54 0C49.3686 0 37.4704 3.60924 27.3502 10.3713C17.23 17.1334 9.3423 26.7447 4.68448 37.9896C0.0266671 49.2346 -1.19203 61.6082 1.1825 73.5458C3.55703 85.4834 9.41816 96.4488 18.0247 105.055C26.6312 113.662 37.5966 119.523 49.5342 121.898C61.4718 124.272 73.8454 123.053 85.0904 118.396C96.3353 113.738 105.947 105.85 112.709 95.7298C119.471 85.6096 123.08 73.7114 123.08 61.54C123.08 45.2186 116.596 29.5656 105.055 18.0246C93.5144 6.48361 77.8615 0 61.54 0V0Z"
        fill="#179039"
      />
      <path
        d="M85.95 33.2099C85.9624 39.7738 85.4777 46.3292 84.5 52.8199C83.29 60.9099 81.5 67.0299 78.94 72.0799C75.88 78.0799 71.45 82.7199 66.47 85.1399C62.8106 86.8706 58.7459 87.5625 54.72 87.1399C51.6862 86.835 48.7499 85.8983 46.1 84.3899C40.52 81.1399 36.27 74.5199 35.29 67.5199C34.82 64.1799 34.75 57.8399 39.5 52.8999C43.4 48.8399 49.63 47.2899 56.22 45.6499C57.86 45.2399 59.56 44.82 61.22 44.35C69.9602 41.8603 78.2886 38.1037 85.94 33.1999M90.27 23.8899C90.0417 23.8681 89.8134 23.9323 89.63 24.0699L88.82 24.67C80.0727 31.1849 70.2264 36.0758 59.75 39.11C51.23 41.49 41.75 42.75 35.57 49.11C24.3 60.83 30.28 81.47 43.36 89.11C47.2185 91.3048 51.5499 92.5355 55.986 92.6974C60.4221 92.8593 64.8318 91.9477 68.84 90.04C75.4 86.85 80.46 81.0399 83.77 74.5699C87.08 68.0999 88.77 60.8599 89.87 53.6399C91.3074 44.0879 91.7231 34.41 91.11 24.7699C91.0925 24.5511 90.9989 24.3452 90.8455 24.1882C90.692 24.0311 90.4884 23.9326 90.27 23.91V23.8899Z"
        fill="#179039"
      />
      <path
        d="M49.1701 72.77L47.0001 71.71C46.8085 71.6183 46.6385 71.4873 46.501 71.3255C46.3636 71.1636 46.2618 70.9746 46.2024 70.7708C46.1429 70.5669 46.1271 70.3528 46.156 70.1425C46.1849 69.9321 46.2578 69.7303 46.37 69.55C48.8154 65.6879 52.0442 62.382 55.8476 59.8464C59.6511 57.3108 63.9445 55.6018 68.45 54.83C68.6535 54.7914 68.8626 54.7946 69.0648 54.8392C69.2669 54.8838 69.458 54.969 69.6262 55.0896C69.7945 55.2102 69.9366 55.3637 70.0438 55.5409C70.151 55.718 70.2212 55.915 70.2501 56.12L70.5601 58.5C70.6131 58.8831 70.5172 59.2719 70.2921 59.5863C70.067 59.9008 69.7298 60.1169 69.3501 60.19C65.6502 60.835 62.1222 62.2329 58.9846 64.297C55.8471 66.3612 53.1669 69.0477 51.1101 72.19C50.916 72.5098 50.6101 72.7462 50.2517 72.8534C49.8933 72.9605 49.5078 72.9308 49.1701 72.77V72.77Z"
        fill="#179039"
      />
    </g>
    <defs>
      <clipPath id="clip0_2455_67603">
        <rect width="123.07" height="123.07" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

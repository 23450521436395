import React from 'react';

export const Delete = ({ color = '#242424' }: { color: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 24 25" fill="none">
    <path d="M3.75 6.08496H20.25" stroke={color} strokeMiterlimit="10" />
    <path d="M12 9.08521V19.5852" stroke={color} strokeLinejoin="round" />
    <path d="M8.62506 9.08521L9.00006 19.5852" stroke={color} strokeLinejoin="round" />
    <path d="M15.3751 9.08521L15.0001 19.5852" stroke={color} strokeLinejoin="round" />
    <path d="M5 6.33447L6 22.3345H18L19 5.83447" stroke={color} />
    <path d="M8 5.83447V3.83447H16V5.83447" stroke={color} />
  </svg>
);

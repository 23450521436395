import React from 'react';

export const Collection = ({ color = '#F8F8FD' }: { color: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 56 57" fill="none">
    <rect x="15" y="15.8345" width="10" height="10" stroke={color} strokeWidth="2" />
    <rect x="15" y="31.8345" width="10" height="10" stroke={color} strokeWidth="2" />
    <rect x="31" y="15.8345" width="10" height="10" stroke={color} strokeWidth="2" />
    <rect x="31" y="31.8345" width="10" height="10" stroke={color} strokeWidth="2" />
  </svg>
);

import React from 'react';

export const Image = ({ color = '#7C7F9D' }: { color: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none">
    <rect x="15" y="15.1655" width="26" height="26" stroke={color} strokeWidth="2" />
    <path d="M15 32.6655L22 25.6655L37.5 41.1655" stroke={color} strokeWidth="2" />
    <path d="M31 35.1655L41 25.1655" stroke={color} strokeWidth="2" />
    <circle cx="31.5" cy="22.6655" r="2.5" stroke={color} strokeWidth="2" />
  </svg>
);

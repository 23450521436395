import { css } from '@emotion/react';
import React from 'react';
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as Icons from './icons';

export type IconType = keyof typeof Icons;

const Icon = ({
  icon,
  heigth = 24,
  width = 24,
  size,
  color = '#242424',
  ...rest
}: {
  icon: IconType;
  heigth?: number;
  width?: number;
  size?: number;
  color?: string;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const SVG = Icons[icon];
  if (!!size) {
    heigth = size;
    width = size;
  }
  return (
    <div {...rest}>
      <div
        css={css`
          height: ${heigth}px !important;
          width: ${width}px !important;
          min-height: ${heigth}px !important;
          min-width: ${width}px !important;
          box-sizing: border-box;
          svg {
            display: block;
          }
        `}
      >
        <SVG color={color} />
      </div>
    </div>
  );
};

export default Icon;

export default {
  black: '#0d0d12',
  bg1: '#f8f8fd',
  bg2: '#202333',
  bg3: '#F3EDE7',
  action: '#75003f',
  lightGray: '#b9bbcf',
  darkGray: '#4A5071',
  error: '#c83d56',
  background: '#f9f6f3',
  ui: '#7c7f9d',
  type: '#24252C',
  textBlack: '#242424',
  sustainable: '#179039',
};

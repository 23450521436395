import React from 'react';

export const Sort = ({ color = '#24252C' }: { color: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 16 17" fill="none">
    <path d="M7.23303 7.06758L4.16545 4L1.09788 7.06758" stroke={color} />
    <path d="M4.16553 13.6689V4.5" stroke={color} />
    <path d="M8.76697 10.6014L11.8345 13.6689L14.9021 10.6014" stroke={color} />
    <path d="M11.8345 4L11.8345 13" stroke={color} />
  </svg>
);
